import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DashboardPage } from './pages/Dashboard';
import { NotFound } from './pages/NotFound';
import { Nav} from './components/Nav'

function App() {

  let props = {
    user: {
      avatarUrl: 'https://cdn.discordapp.com/avatars/497531521981349890/a_c54e28e320570dc04003078bf93d8ff3.gif?size=4096',
        username: 'Zeedz',
        discrim: '#0001',
        config: {
            aboutMe: 'Hey! I love coding. :)',
            location: 'Australia',
            gender: 'male',
            job: 'Backend Software Developer'
        },
        badges: [],
        bannerUrl: 'https://cdn.discordapp.com/banners/497531521981349890/a_f5b3a3ca2098b65dd22c0421fa924ad8.gif?size=512',
    }
  }

  return (
    <div>
      <Nav />
      <Switch>
        <Route path="/user/customise" exact={true} render={() => (<DashboardPage {...props} />)} />

        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;
