import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

const primary = '#191d2a'
const secondary = '#16151d'
const blurple = '#5865F2'

export const Navbar = styled.nav`
  background: #63D471;
  height: 85px;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
  /* Third Nav */
  /* justify-content: flex-start; */
`;
  
export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;
  
export const NavBurger = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Bars = styled.div`
  display: none;
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 10px 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
  
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;

export const DashboardMain = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 5vh 5vh 5vh;
  padding-top: 2em;
  flex-direction: row;

  @media screen and (max-width: 1140px) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding-left: 1em;
  }
`;

export const DashboardPreview = styled.div`
  background-color: ${primary};
  color: white;
  padding: 1em;
  border-radius: 10px;
  width: 45%;
  margin-bottom: 5vh;
  
  @media screen and (max-width: 1140px) {
    width: 80%
  }
`;

export const DashboardInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #16151d;
  color: white;
  padding: 1em;
  margin: 1em;
`;

export const DashboardForm = styled.form`
  width: 45%;
  background-color: ${primary};
  color: white;
  border-radius: 10px;
  padding: 1em 1em 1em 1em;

  @media screen and (max-width: 1140px) {
    width: 80%
  }
`;

export const DashboardTextArea = styled.textarea`
  color: white;
  resize: none;
  background-color: #16151d;
  border: none;
  border-radius: 0px;
  caret-color: white;
  font-family: 'Roboto', sans-serif;
  font-size: large;
  &:focus {
    border: none;
    outline: none;
  }
`;

export const DashboardFormLabel = styled.label`
  color: ${blurple};
`;

export const DashboardSelect = styled.select`
  color: white;
  background-color: ${secondary};
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: large;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  &:focus {
    border: none;
    outline: none;
  }
  &.selected {
    border: none;
  }
`;

export const DashboardSelectItem  = styled.option`
  color: white;
  padding: 12px 16px;
  text-decoration: none;
`;

export const LocationGenderJob = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DashboardPreviewH3 = styled.h4`
  color: ${blurple};
`;

export const WholePreview = styled.div`
  border-radius: 20px;
  position: relative;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  height: 6.5rem;
  width: 6.5rem;
  margin-top: 18rem;
  margin-left: 2rem;
  z-index: 10;
  position: relative;
`;

export const AvatarHolder = styled.div`
  
`;
export const Banner = styled.img`
  transform: translate(0px, -120%);
  z-index: 5;
  max-width: 100%
  transform-origin: left top;
  height: 50%;
  display: inline-block;
  position: absolute;

  @media screen and (max-width: 1140px) {
    max-width: 50vh;
  }
`;

export const SaveChanges = styled.button`
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: green;
  border: none;
  color: white;
  cursor: pointer;
`;