import * as React from 'react'
import { DashboardComponent, User } from '../components/Dashboard'

export type Props = {
    user: User
}



export const DashboardPage = (props: Props) => (
    <div style={{backgroundColor: "#16151d", height: '100vh'}}>
        <DashboardComponent user={props.user} />
    </div>
)