import * as React from 'react'
import { Nav as NavBar } from 'rsuite'
export const Nav = () => {
    return (
        <NavBar appearance="default">
          <NavBar.Item href="#home">
            Home
          </NavBar.Item>
        </NavBar>
    )
}
/*
function myFunction() {
    var x: any = document.getElementById("myTopnav");
    x.classList.toggle('active')

  }

*/