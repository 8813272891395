import * as React from 'react';
import { useState } from 'react'
import { Props } from '../pages/Dashboard';
import { DashboardPreview, SaveChanges, Banner, AvatarHolder, DashboardPreviewH3, DashboardMain, DashboardInputDiv, DashboardForm, DashboardTextArea, DashboardFormLabel, DashboardSelect, DashboardSelectItem, LocationGenderJob, WholePreview, Avatar } from '../utils/styles'

export type User = {
    avatarUrl: string,
    username: string,
    discrim: string,
    config: Config,
    badges: string[],
    bannerUrl: string
}

export const DashboardComponent = (props: Props) => {
    const [user, setUser] = useState(/* user object */ props.user)

    const [aboutMe, setAboutMe] = useState(/* aboutMe value */user.config.aboutMe)
    const [location, setLocation] = useState(/* location value*/user.config.location)
    const [gender, setGender] = useState(/* gender value */user.config.gender)
    const [job, setJob] = useState(user.config.job)
    return (
        <DashboardMain>
            <DashboardForm>
                <h3>Customise your profile</h3>
                <DashboardInputDiv>
                    <DashboardFormLabel> <h5>About Me ({200 - aboutMe.length} left)</h5></DashboardFormLabel>
                    <DashboardTextArea onChange={event => setAboutMe(event.target.value)} value={aboutMe} maxLength={200} name="aboutMeInput" id="aboutMeInput" cols={50} rows={4}></DashboardTextArea>
                </DashboardInputDiv>
                <DashboardInputDiv>
                    <DashboardFormLabel><h5>Location ({50 - location.length} left)</h5></DashboardFormLabel>
                    <DashboardTextArea onChange={event => setLocation(event.target.value)} value={location} maxLength={50} name="locationInput" id="locationInput" cols={100} rows={1}></DashboardTextArea>
                </DashboardInputDiv>
                <DashboardInputDiv>
                    <DashboardFormLabel><h5>Gender</h5></DashboardFormLabel>
                    <DashboardSelect onChange={event => setGender(event.target.value)} value={gender} name="genderInput" id="genderInput">
                        <DashboardSelectItem value="male">Male</DashboardSelectItem>
                        <DashboardSelectItem value="female">Female</DashboardSelectItem>
                        <DashboardSelectItem value="nonbinary">Nonbinary</DashboardSelectItem>
                        <DashboardSelectItem value="rathernotsay">Rather Not Say</DashboardSelectItem>
                    </DashboardSelect>
                </DashboardInputDiv>
                <DashboardInputDiv>
                    <DashboardFormLabel><h5>Job ({100 - job.length} left)</h5></DashboardFormLabel>
                    <DashboardTextArea onChange={event => setJob(event.target.value)} value={job} maxLength={100} name='jobInput' id="locationInput" cols={100} rows={1}></DashboardTextArea>
                </DashboardInputDiv>
                <SaveChanges onClick={event => handleSubmit(event, user, { aboutMe, location, gender, job})} >
                    Save Changes
                </SaveChanges>
            </DashboardForm>
            <DashboardPreview>
                <h3>Preview</h3>
                <WholePreview>
                    {getPreview({
                        aboutMe,
                        location,
                        gender,
                        user,
                        job
                    })}
                </WholePreview>
            </DashboardPreview>
        </DashboardMain>
    )
}

async function handleSubmit (event: any, user: User, config: Config) {
    event.preventDefault()
    
    await fetch('https://api.disteps.net/profiles/user/customise/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(config)
    })
}

export type Config = {
    aboutMe: string,
    location: string,
    gender: string,
    job: string
}

type otherConfig = {
    aboutMe: string,
    location: string,
    gender: string,
    user: User,
    job: string
}

function getPreview (config: otherConfig) {

    return (
        <div>
            <AvatarHolder>
                <Avatar src={config.user.avatarUrl} draggable={false} />
            </AvatarHolder>
            <Banner src={config.user.bannerUrl} />
            {config.aboutMe.length > 0 && <p style={{padding: '1em', alignItems: 'center'}}>{config.aboutMe}</p>}
            <LocationGenderJob style={{alignItems: 'center'}}>
                {config.location.length > 0 && <div><DashboardPreviewH3>Location</DashboardPreviewH3> <p style={{padding:'1em'}}>{config.location}</p></div>}
                {config.gender !== 'rathernotsay' && <div><DashboardPreviewH3>Gender</DashboardPreviewH3><p style={{padding: '1em'}}>{config.gender[0].toUpperCase()}{config.gender.slice(1)}</p></div>}
                {config.job.length > 0 && <div><DashboardPreviewH3>Job</DashboardPreviewH3><p style={{padding:'1em'}}>{config.job}</p></div>}
            </LocationGenderJob>
        </div>
    )
}